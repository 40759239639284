<template>
    <div>
        <add-to-job :job="entityRecord"></add-to-job>
        <strong class="panel-section-header mt-0">
            Schedule
        </strong>
        <v-table density="compact" class="text-caption">
            <tbody>
                <tr v-if="entityRecord.activeTask">
                    <th class="w-20">Status</th>
                    <td class="w-80">
                        <type-chip :type="entityRecord.activeTask.status" entity="Job" :object="entityRecord"></type-chip>
                    </td>
                </tr>
                <tr v-if="entityRecord.outstandingSubtotal != entityRecord.jobSubTotal && entityRecord.outstandingSubtotal != 0">
                    <th class="w-20">Oustanding</th>
                    <td class="w-80">
                        ${{ numberWithCommas(entityRecord.outstandingSubtotal, 2) }}
                        <v-chip v-if="entityRecord.remainingPercentage && entityRecord.remainingPercentage != 1"
                                :prepend-icon="entityRecord.remainingPercentage > 0 ? 'mdi-list-status' : 'mdi-check-circle'"
                                size="small"
                                :color="entityRecord.remainingPercentage > 0 ? 'warning' : 'success'">{{ numberWithCommas(entityRecord.remainingPercentage, 0) }}%</v-chip>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <v-btn @click="openJobReworkDialog(entityRecord)"
               v-if="entityRecord.status != $constants.jobs.JOBSTAT_SCHEDULE"
               prepend-icon="mdi-recycle"
               block
               variant="tonal"
               color="red-darken-1"
                size="small"
                class="mt-2 border-radius-0"
                @click.stop>Add Rework</v-btn>
        <div v-if="entityRecord.archivedJobTasks">
            <v-table density="compact" class="text-caption task-table">
                <tbody>
                    <tr>
                        <th class="w-40 pa-1">Completed Task</th>
                        <th class="w-40 text-right">Date</th>
                        <th class="w-20 text-right">$</th>
                    </tr>
                </tbody>
                <tbody v-for="archivedTask in entityRecord.archivedJobTasks" :key="archivedTask.id">
                    <tr>
                        <td class="pa-1">{{ archivedTask.name }} <entity-tag-chip :object="archivedTask" class="ml-1"></entity-tag-chip></td>
                        <td class="text-right"><span v-if="archivedTask.targetStartDate">{{ $dayjs(archivedTask.targetStartDate).format('DD-MM-YYYY') }}</span></td>
                        <td class="text-right"><span v-if="archivedTask.estimatedSubtotalCompleted">${{ numberWithCommas(archivedTask.estimatedSubtotalCompleted, 2) }}</span></td>
                    </tr>
                    <tr v-for="time in archivedTask.jobTaskTimes" :key="time.id" class="time-row">
                        <td colspan="3">
                            <div class="d-flex justify-space-between">
                                <div><v-icon icon="mdi-timeline-clock-outline"></v-icon> <b>{{ numberWithCommas($dayjs(time.endTime).diff($dayjs(time.startTime), 'minute'), 0) }}mins</b></div>
                                <div class="text-right">{{ $dayjs(time.startTime).format('hh:mm') }} - {{ $dayjs(time.endTime).format('hh:mma') }}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </div>
        <strong class="panel-section-header mt-0">
            Details
        </strong>
        <v-table density="compact" class="text-caption">
            <tbody>
                <tr align="right" :class="getTagClass(entityRecord.jobTag)">
                    <th class="w-20">Tag</th>
                    <td class="w-80">
                        <v-select v-model="entityRecord.jobTag"
                                  density="compact"
                                  @update:modelValue="updateJob('jobTag')"
                                  :items="jobTags"></v-select>
                    </td>
                </tr>
                <tr v-if="entityRecord.customerNumber" align="right">
                    <th>Created</th>
                    <td>{{ entityRecord.dateCreated }}</td>
                </tr>
                <tr v-if="entityRecord.contact" align="right">
                    <th>Contact</th>
                    <td>
                        <contact-link :contact="entityRecord.contact"></contact-link>
                    </td>
                </tr>
                <tr align="right">
                    <td colspan="2">
                        <new-email-btn v-if="entityRecord.contact" :address="entityRecord.contact.accountsEmail"
                                       :name="entityRecord.contact.name"
                                       :contact="entityRecord.contact"
                                       :job="entityRecord"></new-email-btn>
                    </td>
                </tr>
                <tr v-if="entityRecord.name" align="right">
                    <th>Name</th>
                    <td>{{ entityRecord.name }}</td>
                </tr>
                <tr v-if="entityRecord.customerNumber" align="right">
                    <th>Customer#</th>
                    <td>{{ entityRecord.customerNumber }}</td>
                </tr>
                <tr v-if="entityRecord.projectName" align="right">
                    <th>Project</th>
                    <td>{{ entityRecord.projectName }}</td>
                </tr>
                <tr v-if="entityRecord.dateReceiveEstimate && !entityRecord.dateReceived" align="right">
                    <th>Estimated Receive Date</th>
                    <td>
                        {{ $dayjs(entityRecord.dateReceiveEstimate).format('DD-MM-YYYY') }}
                        <date-diff-chip :date="entityRecord.dateReceiveEstimate" :countdown="false"></date-diff-chip>
                    </td>
                </tr>
                <tr v-if="entityRecord.dateReceived" align="right">
                    <th>Receive Date</th>
                    <td>
                        <date-ago-display :date="entityRecord.dateReceived" :days-ago="entityRecord.daysSinceReceived" :countdown="false"></date-ago-display>
                    </td>
                </tr>
                <tr v-if="entityRecord.customerDateTargetCompletion && !entityRecord.dateCompleted" align="right">
                    <th>Customer Requested Target Completion Date</th>
                    <td>
                        {{ $dayjs(entityRecord.customerDateTargetCompletion).format('DD-MM-YYYY') }}
                        <date-diff-chip :date="entityRecord.customerDateTargetCompletion" :countdown="true"></date-diff-chip>
                    </td>
                </tr>
                <tr v-if="entityRecord.dateTargetCompletion && !entityRecord.dateCompleted" align="right">
                    <th>Target Completion Date</th>
                    <td>
                        {{ $dayjs(entityRecord.dateTargetCompletion).format('DD-MM-YYYY') }}
                        <date-diff-chip :date="entityRecord.dateTargetCompletion" :countdown="true"></date-diff-chip>
                    </td>
                </tr>
                <tr v-if="entityRecord.dateCompleted" align="right">
                    <th>Completion Date</th>
                    <td>{{ $dayjs(entityRecord.dateCompleted).format('DD-MM-YYYY') }}</td>
                </tr>
                <tr>
                    <th>Powder Usage</th>
                    <td>
                        <v-text-field v-model.number="entityRecord.totalPowderUsage"
                                      variant="underlined"
                                      @update:modelValue="updateJob('totalPowderUsage')"
                                      type="number"
                                      suffix="KGs"></v-text-field>
                    </td>
                </tr>
                <tr>
                    <th>Line Time</th>
                    <td>
                        <v-text-field v-model.number="entityRecord.totalLineTime"
                                      variant="underlined"
                                      type="number"
                                      disabled
                                      suffix="mins"></v-text-field>
                    </td>
                </tr>
                <tr v-if="entityRecord.dollarsPerLineHour">
                    <th>
                        $ per hour
                    </th>
                    <td>
                        <h3>${{ numberWithCommas(entityRecord.dollarsPerLineHour, 2) }}</h3>
                    </td>
                </tr>
                <tr v-if="entityRecord.idealSell">
                    <th>
                        Ideal Sell <small><i>(Line {{ entityRecord.idealSellLine }})</i></small>
                    </th>
                    <td>
                        <h3>${{ numberWithCommas(entityRecord.idealSell, 2) }}</h3>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <v-btn :to="'/'+$constants.entities.Job.ROUTE+entityRecord.id+'/jobsheet/'"
               variant="tonal"
               color="primary"
               size="small"
               block
               prepend-icon="mdi-printer"
               class="mt-2 border-radius-0"
               @click.stop>
            Job Card
        </v-btn>
        <strong class="panel-section-header mt-0">
            Powder
        </strong>
        <v-table density="compact" class="text-caption">
            <tbody>
                <tr>
                    <th>Colour</th>
                    <th class="text-right">Est.Coat</th>
                    <th class="text-right">Est.Powder</th>
                </tr>
                <tr v-for="colour in entityRecord.jobColours" :key="colour.colour">
                    <td>{{ colour.name }}</td>
                    <td class="text-right">{{ numberWithCommas(colour.estimateCoatingTime, 0) }} mins</td>
                    <td class="text-right">{{ numberWithCommas(colour.powderRequired, 0) }} Kgs</td>
                </tr>
            </tbody>
        </v-table>
        <!--<div v-if="entityRecord.status > 0 || entityRecord.archivedJobTasks">
        <strong class="panel-section-header">Tasks</strong>
        <job-tasks-small :items="entityRecord.jobTasks" :current-task="entityRecord.status" :archived="entityRecord.archivedJobTasks"></job-tasks-small>
        <div class="pa-2">
            <reschedule-job-button size="small" :block="true" :job="entityRecord"></reschedule-job-button>
        </div>
    </div>-->
        <div v-if="entityRecord.invoices">
            <strong class="panel-section-header">
                Invoices
            </strong>
            <div class="overflow-x-hidden">
                <v-table density="compact" class="text-caption">
                    <tbody>
                        <tr>
                            <th>Invoice</th>
                            <th>Status</th>
                            <th>Amount</th>
                        </tr>
                        <tr v-for="invoice in entityRecord.invoices" :key="invoice.id">
                            <td>{{ invoice.invoiceNumber }}</td>
                            <td><invoice-status :status="invoice.status"></invoice-status></td>
                            <td>${{ numberWithCommas(invoice.totalAmount, 2) }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </div>
        </div>
        <job-reset class="my-1" v-if="hasManagerAccess && $windowWidth > 960" :job="entityRecord"></job-reset>
    </div>
</template>
<style scoped>
    .sub-panel-header {
        background-color: rgba(0,0,0,.03);
    }
    .text-caption tr > th {
        height: 12px !important;
    }
    .time-row {
        background: #f0f0f0;
    }
    .task-table tbody > tr > th {
        border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    }
    .task-table tbody > tr > td {
        border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    }
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import jobs from "../../constants/jobs";
    import { getTagClass } from '../../mixins/jobMixins';
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "JobDetailsPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            numberWithCommas,
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "patchEntity",
                "openJobReworkDialog",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            updateJob(field){
                this.patchEntity({path:field, value: this.entityRecord[field]});
            },
            getTagClass
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                entityRecord: (state) => state.entities.entityRecord,
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
            ...mapGetters(['hasManagerAccess']),
            jobTags() {
                return jobs.tags.map(item => item.name);
            },
            statuses() {
                return jobs.statuses;
            },
        }
    };
</script>