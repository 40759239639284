<template>
    <div>
        <div>
            <v-row>
                <v-col cols="2">
                    <v-text-field label="Start Date"
                                  type="date"
                                  @change="loadReport"
                                  v-model="startDate"></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-text-field label="End Date"
                                  type="date"
                                  @change="loadReport"
                                  v-model="endDate"></v-text-field>
                </v-col>
                <v-col cols="2">
                    <div><small>LINE FILTER</small></div>
                    <v-btn-toggle v-model="lineSalesType"
                                  variant="outlined"
                                  density="compact"
                                  divided
                                  group>
                        <v-btn value="All" @click="loadReport">
                            All
                        </v-btn>

                        <v-btn value="Line 1"  @click="loadReport">
                            Line 1
                        </v-btn>

                        <v-btn value="Line 2"  @click="loadReport">
                            Line 2
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
                <v-col cols="2">
                    <v-btn :loading="downloading" @click="downloadReport">
                        Download Excel
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-alert v-if="errors" :text="errors" type="warning"></v-alert>
                </v-col>
            </v-row>
        </div>
        <div>
            <v-overlay :model-value="loading"
                       class="align-center justify-center">
                <v-progress-circular color="primary"
                                     indeterminate
                                     size="64"></v-progress-circular>
            </v-overlay>
            <v-table class="contact-sum-table">
                <thead>
                    <tr>
                        <th class="w-10">Contact</th>
                        <th v-for="header in headers" :key="header" style="width:6.5%" class="number-cell">{{ header }}</th>
                        <th class="number-cell">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in data" :key="row.name">
                        <td>
                            <contact-link :contact="row.contact"></contact-link>
                        </td>
                        <td v-for="(month, index) in row.monthData" :key="index" class="number-cell">
                            {{ numberWithCommas(month, 2)  }}
                        </td>
                        <td class="number-cell">
                            <strong>{{ numberWithCommas(contactTotal(row.monthData), 2) }}</strong>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </div>
    </div>
</template>
<style scoped>
    .number-cell {
        text-align:right!important;
    }
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false,
            lineSalesType: 'All',
            startDate: null,
            errors: '',
            endDate: null,
            headers: [],
            data: [],
            downloading: false
        };
    }
    export default {
        name: "ContactSalesSummaryReport",
        data: function () {
            return initialState();
        },
        mounted: function () {
            // Set as start of this month and 12 months ago
            this.endDate = this.$dayjs().startOf('month').subtract(1, 'day').format('YYYY-MM-DD');
            this.startDate = this.$dayjs().startOf('month').subtract(12, 'month').format('YYYY-MM-DD');
            this.loadReport();
        },
        props: {
            definition: Object
        },
        methods: {
            downloadReport() {
                let dates = this.getDates();
                if (!dates) return;
                this.downloading = true;
                let query = [];
                query.push('startDate=' + dates.sDate.format('YYYY-MM-DD'));
                query.push('endDate=' + dates.eDate.format('YYYY-MM-DD'));
                query.push('lineFilter=' + this.lineSalesType);
                this.$api.request({
                        method: 'GET', url: this.definition.route + "download?" + query.join('&'),
                        responseType: 'blob'
                    })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'contactsalessummary.xls');
                        document.body.appendChild(link);
                        link.click();
                        this.downloading = false;
                    });
            },
            isValidDate(date) {
                return this.$dayjs.isDayjs(date);
            },
            getDates() {
                let sDate = this.$dayjs(this.startDate);
                let eDate = this.$dayjs(this.endDate);
                if (!this.startDate || !this.endDate || !this.isValidDate(sDate) || !this.isValidDate(eDate)) {
                    this.errors = "Need a start and end date";
                    return;
                }
                if (!sDate.isBefore(eDate)) {
                    this.errors = "Start date must be before end date";
                    return;
                }
                // Check if range is greater then 12 months ago
                if (!eDate.isSameOrBefore(sDate.add(12, 'month'))) {
                    this.errors = "Dates must be within 12 months of each other";
                    return;
                }
                return { sDate, eDate };
            },
            loadReport(){
                let dates = this.getDates();
                if (!dates) return;
                this.errors = null;
                // All good - Run
                this.loading = true;
                let query = [];
                query.push('startDate=' +dates.sDate.format('YYYY-MM-DD'));
                query.push('endDate=' +dates.eDate.format('YYYY-MM-DD'));
                query.push('lineFilter='+this.lineSalesType);
                this.$api.get(this.definition.route+"?"+query.join('&'))
                    .then(res => {
                        this.loading = false;
                        this.headers = res.data.headers;
                        this.data = res.data.data;
                    }).catch(error => {
                        this.loading = false;
                    });
            },
            numberWithCommas,
            contactTotal(months){
                let total = 0;
                months.forEach(item => {
                    total += item;
                });
                return total;
            }
        },
        computed: {
        }
    };
</script>