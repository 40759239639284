<template>
    <v-dialog v-model="showJobReworkDialog"
              min-height="80%"
              :fullscreen="$windowWidth < 960"
              width="auto"
              scrollable
              persistent>
        <v-card>
            <v-toolbar theme="dark">
                <v-toolbar-title>Add Rework for {{ dialogJob.name }}</v-toolbar-title>
                <v-btn icon="mdi-close"
                       @click="showJobReworkDialog = false"></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <div>
                            Job currently has an outstanding amount of ${{ numberWithCommas(dialogJob.taskSubTotal, 2) }}.
                        </div>
                        <div v-if="reworkTotalValue && reworkTotalValue > 0">
                            <h2><small>Estimated rework amount </small> ${{ numberWithCommas(reworkTotalValue, 2) }}</h2>
                            <h4><small>Estimated outstanding amount</small> ${{ numberWithCommas(dialogJob.taskSubTotal-reworkTotalValue, 2) }} ({{ numberWithCommas(((dialogJob.taskSubTotal-reworkTotalValue)/dialogJob.taskSubTotal)*100, 2) }}%)</h4>
                        </div>
                        <hr class="my-2" />
                        <i>Select the items below that required rework.</i>
                        <job-task-item-select-table :job-item-tasks="reworkItems"></job-task-item-select-table>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p>What is the next step for resolution?</p>
                        <v-radio-group v-model="status">
                            <v-radio label="Reworking Today" :value="$constants.jobs.JOBSTAT_INVOICE"></v-radio>
                            <v-radio label="Reschedule" :value="$constants.jobs.JOBSTAT_SCHEDULE"></v-radio>
                            <v-radio label="Stripping" :value="$constants.jobs.JOBSTAT_STRIPPING"></v-radio>
                            <v-radio label="Blasting" :value="$constants.jobs.JOBSTAT_BLASTING"></v-radio>
                        </v-radio-group>
                        <small v-if="status == $constants.jobs.JOBSTAT_INVOICE">If marking as reworking today - this will move the job to ready to invoice.</small>
                        <div v-if="status == $constants.jobs.JOBSTAT_SCHEDULE">
                            <v-text-field label="Reschedule Date"
                                          type="date"
                                          :hide-details="true"
                                          v-model="rescheduleDate"></v-text-field>
                        </div>
                        <p>What is the reason for the rework?</p>
                        <v-chip-group selected-class="text-primary"
                                      v-model="selectedReasons"
                                      multiple>
                            <v-chip v-for="tag in reasonChips"
                                    size="x-small"
                                    :prepend-icon="tag.icon"
                                    :key="tag.reason">
                                {{ tag.reason }}
                            </v-chip>
                        </v-chip-group>
                        <v-textarea label="Additional Information" v-model="reworkAddReason"></v-textarea>
                        <v-alert icon="mdi-alert-decagram-outline"
                                 class="my-2"
                                 v-if="reworkErrors.length > 0"
                                 type="warning">
                            <ul class="small-text">
                                <li :key="error" v-for="error in reworkErrors">{{ error }}</li>
                            </ul>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="saveRework"
                       :loading="saving"
                       :disabled="reworkErrors.length > 0"
                       prepend-icon="mdi-check-circle"
                       color="success">
                    SAVE REWORK
                </v-btn>
                <v-btn dark @click="showJobReworkDialog = false" :disabled="saving">
                    CANCEL
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped lang="scss">
    @import '../../../styles/smart.scss'
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { formatSearchName } from "../../../mixins/searchMixins";
    import { numberWithCommas } from "../../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            rescheduleDate: null,
            reworkItems: [],
            status: null,
            selectedReasons: [],
            reworkAddReason: '',
            reasonChips: [
                { icon: 'mdi-bug-outline', reason: 'Scratch'},
                { icon: 'mdi-hanger', reason: 'Fell in oven' },
                { icon: 'mdi-hanger', reason: 'Not hung correctly for faces to coat' },
                { icon: 'mdi-hanger', reason: 'Touched in oven' },
                { icon: 'mdi-hanger', reason: 'Product dirty' },
                { icon: 'mdi-spray', reason: 'Too much powder' },
                { icon: 'mdi-spray', reason: 'Not enough powder' },
                { icon: 'mdi-spray', reason: 'Wrong colour' },
                { icon: 'mdi-spray', reason: 'Powder colour cross contamination' },
                { icon: 'mdi-archive-outline', reason: 'Damaged during packing' }
            ]
        };
    }
    export default {
        name: "SmartJobReworkDialog",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            job: Object,
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "loadSmartData",
                "reloadEntities"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName,
            numberWithCommas,
            resetInput() {
                this.status = null;
                this.rescheduleDate = null;
                this.selectedReasons = [];
                this.reworkItems = [];
                this.reworkAddReason = '';
                if (this.dialogJob && this.dialogJob.taskDate) {
                    let currentDate = this.$dayjs(this.dialogJob.taskDate).add(1, 'day');
                    this.rescheduleDate = (currentDate.day() === 6
                        ? currentDate.add(2, 'day')  // Subtract 3 days for Monday -> Friday
                        : currentDate.day() === 0 ? currentDate.add(1, 'day') : currentDate).format('YYYY-MM-DD');
                    this.dialogJob.jobItems.forEach(item => {
                        this.reworkItems.push({
                            id: item.id,
                            quantity: 0,
                            description: item.description,
                            itemQuantity: item.quantity,
                            totalUnitAmount: item.totalUnitAmount
                        });
                    });
                }
            },
            saveRework() {
                this.saving = true;
                let formData = new FormData();
                formData.append('id', this.dialogJob.jobId);
                if (this.dialogJob.jobTaskId) {
                    formData.append('taskId', this.dialogJob.jobTaskId);
                }
                formData.append('partiallyCompleted', true)
                formData.append("status", this.status);
                if (this.status == this.$constants.jobs.JOBSTAT_SCHEDULE) {
                    formData.append("rescheduleDate", this.rescheduleDate);
                }
                formData.append("reasonJsonList", JSON.stringify(this.selectedReasonText))
                formData.append("comment", this.reworkAddReason);
                let i = 0;
                this.selectedReworkItems.forEach(item => {
                    formData.append(`reworkJobItems[${i}].id`, item.id);
                    formData.append(`reworkJobItems[${i}].quantity`, item.quantity);
                    i++
                });
                if (this.smartStartDate){
                     formData.append('startDate', this.smartStartDate);
                }
                if (this.smartEndDate){
                    formData.append('endDate', this.smartEndDate);
                }
                this.$api.post(this.$constants.entities.Job.ROUTE + 'completejob', formData)
                    .then(res => {
                        if (this.entityRecord) {
                            this.reloadEntities();
                            this.showJobReworkDialog = false;
                        } else {
                            this.loadSmartData(res.data);
                        }
                        this.saving = false;
                    })
                    .catch(error => {
                        this.showErrorSnack('There was an issue saving the rework');
                    });
            },
        },
        computed: {
            ...mapState({
                dialogJob: (state) => state.smart.dialogJob,
                smartStartDate: (state) => state.smart.smartStartDate,
                smartEndDate: (state) => state.smart.smartEndDate,
                entityRecord: (state) => state.entities.entityRecord
            }),
            showJobReworkDialog: {
                get() {
                    return this.$store.state.smart.showJobReworkDialog
                },
                set(value) {
                    this.$store.commit('setShowJobReworkDialog', value)
                }
            },
            selectedReasonText() {
                return this.selectedReasons.map(index => {
                    return this.reasonChips[index].reason;
                });
            },
            selectedReworkItems() {
                return this.reworkItems.filter(item => {
                    return item.quantity > 0;
                });
            },
            reworkTotalValue() {
                let result = 0;
                if (this.selectedReworkItems.length > 0) {
                    this.selectedReworkItems.forEach(item => {
                        result += (item.quantity * item.totalUnitAmount);
                    });
                }
                return result;
            },
            reworkErrors() {
                let errors = [];
                if (this.selectedReworkItems.length == 0) {
                    errors.push('Need at least one item to rework');
                }
                if (this.status == this.$constants.jobs.JOBSTAT_SCHEDULE) {
                    if (!this.rescheduleDate || this.rescheduleDate.length == 0) {
                        errors.push('Must have reschedule date');
                    }
                }
                if (this.selectedReasons.length == 0) {
                    errors.push("Need at least one rework reason");
                }
                if (!this.reworkAddReason || this.reworkAddReason.length == 0) {
                    errors.push("Please add a comment on what happened");
                }
                return errors;
            },
        },
        watch: {
            dialogJob: {
                handler: function () {
                    if (this.dialogJob) {
                        this.resetInput();
                    }
                }
            }
        }
    };
</script>