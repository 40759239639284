<template>
    <div :key="smartKey">
        <v-overlay :model-value="loadingData"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="smart-controls d-flex">
            <div class="date-controls">
                <label><v-icon icon="mdi-gamepad-square"></v-icon></label>
                <v-text-field label="Start Date"
                              type="date"
                              density="compact"
                              @change="saveSmartDates"
                              :hide-details="true"
                              v-model="smartStartDate"></v-text-field>
                <v-text-field label="End Date"
                              type="date"
                              density="compact"
                              @change="saveSmartDates"
                              :hide-details="true"
                              v-model="smartEndDate"></v-text-field>
                <v-tooltip text="Hide Yesterday">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" v-if="yesterdayShowing" variant="outlined" class="refresh-btn d-none d-md-inline-block" @click="hideYesterday" icon="mdi-update">
                        </v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Refresh">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" variant="outlined" class="refresh-btn d-none d-md-inline-block" @click="refreshSmartData" icon="mdi-refresh">
                        </v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Hide Archived">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" variant="outlined" class="refresh-btn d-none d-md-inline-block" @click="toggleHideArchived" icon="mdi-eye-off-outline" :active="hideArchived">
                        </v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Show Colours">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" :active="showColours" variant="outlined" class="refresh-btn d-none d-md-inline-block" @click="toggleShowColours" icon="mdi-palette">
                        </v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Lock Drag">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" class="refresh-btn d-block d-md-none" variant="outlined" :icon="!smartLock ? 'mdi-lock-open' : 'mdi-lock'" @click="toggleSmartLock(!smartLock)">
                        </v-btn>
                    </template>
                </v-tooltip>
            </div>
            <div class="job-search d-none d-md-inline-block">
                <v-text-field label="Search cards..."
                              prepend-icon="mdi-magnify"
                              v-model="searchTerm"
                              density="compact"
                              :hide-details="true"
                              clearable></v-text-field>
            </div>
            <div class="d-none d-md-inline-block">
                <v-btn-toggle variant="outlined"
                              divided>
                    <v-btn variant="outlined"
                           prepend-icon="mdi-printer"
                           class="refresh-btn"
                           to="/dashboard/dayprogram"
                           target="_blank">
                        Program
                    </v-btn>
                    <v-btn variant="outlined"
                           prepend-icon="mdi-printer"
                           class="refresh-btn"
                           to="/dashboard/powderreport"
                           target="_blank">
                        Powder
                    </v-btn>
                </v-btn-toggle>
            </div>
        </div>
        <div class="smart-cont" ref="smartCont">
            <div class="smart-area">
                <div v-for="column in filteredColumns" :key="column.name" class="smart-column" :class="{'expand-column' : column.expand, 'shrink-column' : !column.expand }">
                    <div class="column-header">
                        {{ column.name }}  <v-badge :color="column.jobs.length > 0 ? 'info' : 'secondary'" :content="column.jobs.length" inline></v-badge>
                        <v-tooltip :text="column.expand ? 'Hide Column' :'Show Column'">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props"
                                       class="expand-btn"
                                       variant="plain"
                                       @click="expandSmartColumn(column)"
                                       :icon="column.expand ? 'mdi-arrow-expand-up' : 'mdi-arrow-expand-left'"
                                       size="x-small"></v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Bulk Assign" v-if="column.status == $constants.jobs.JOBSTAT_SCHEDULE && column.jobs.length > 0 && column.expand">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props"
                                       color="primary"
                                       @click="showBulkSchedule"
                                       icon="mdi-format-list-text"
                                       class="float-right"
                                       size="x-small"></v-btn>
                            </template>
                        </v-tooltip>
                    </div>
                    <div :class="{'d-none':!column.expand, 'd-inline-block':column.expand}" class="w-100 h-100">
                        <div class="totals-block">
                            <div class="d-flex justify-space-between">
                                <span class="total-label">TOTAL</span>
                                <span>${{ numberWithCommas(columnTotal(column), 2) }}</span>
                            </div>
                            <div class="d-flex justify-space-between scheduled-block">
                                <span class="total-label"><span v-if="column.status >= $constants.jobs.JOBSTAT_LINE1">SCHEDULED</span>&nbsp;</span>
                                <span v-if="column.scheduledTotal">${{ numberWithCommas(column.scheduledTotal, 2) }}</span>
                            </div>
                        </div>
                        <div v-if="column.status >= $constants.jobs.JOBSTAT_LINE1 && showColours" class="colour-block">
                            <div class="text-left">
                                <span class="total-label">COLOURS</span>
                                <table class="w-100">
                                    <tr v-for="colour in columnColours(column)" :key="colour">
                                        <td>{{ colour }}</td>
                                        <td class="text-right">{{ columnColourTotal(colour, column) }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <draggable class="card-list"
                                   :list="column.jobs"
                                   group="jobs"
                                   draggable=".moveable-card"
                                   @change="updateCard(column, $event)"
                                   v-bind="dragOptions"
                                   itemKey="id">
                            <template #item="{ element }">
                                <smart-card :job="element" :search-term="searchTerm"></smart-card>
                            </template>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>
        <smart-job-dialog></smart-job-dialog>
        <smart-job-complete-dialog></smart-job-complete-dialog>
        <smart-bulk-schedule-dialog></smart-bulk-schedule-dialog>
        <v-snackbar v-model="refreshRequired"
                    color="blue-darken-3"
                    :timeout="-1"
                    location="bottom"
                    elevation="24"
                    vertical>
            <div class="text-subtitle-1 pb-2">Changes have been detected</div>

            <p>Please refresh the data to view changes.</p>

            <template v-slot:actions>
                <v-btn color="blue-lighten-5"
                       variant="tonal"
                       prepend-icon="mdi-refresh"
                       @click="refreshSmartData">
                    Refresh Jobs
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<style scoped lang="scss">
    @import '../../styles/smart.scss'
</style>
<script>
    /*
    Blue - Pretreatment Completed
    Red - Rework
    */
    import { mapActions, mapState } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import draggable from 'vuedraggable'
    import Cookies from 'js-cookie';
    function initialState() {
        return {
            searchTerm: '',
            showColours: false,
            dragOptions: {
                animation: 200,
                group: "jobs",
                disabled: false,
                ghostClass: "smart-ghost",
            }
        };
    }
    export default {
        name: "SmartDashboard",
        components: {
            draggable,
        },
        data: function () {
            return initialState();
        },
        mounted: function () {
            window.addEventListener("scroll", this.onScroll, true)
            let colourExpand = Cookies.get('smart-show-colours');
            if (typeof colourExpand !== 'undefined') {
                this.showColours = colourExpand == 'true';
            }
            this.initSmart();
        },
        onMounted: function () {
            window.removeEventListener("scroll", this.onScroll, true)
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "refreshSmartData",
                "updateSmartJobStatus",
                "expandSmartColumn",
                "saveSmartDates",
                "initSmart",
                "showBulkSchedule",
                "toggleSmartLock"
            ]),
            onScroll(e) {
                this.smartScrollPos = e.target.scrollTop;
            },
            numberWithCommas,
            toggleShowColours() {
                this.showColours = !this.showColours;
                 Cookies.set('smart-show-colours', this.showColours);
            },
            hideYesterday() {
                this.smartStartDate = this.$dayjs().format('YYYY-MM-DD');
                this.saveSmartDates();
            },
            toggleHideArchived() {
                this.hideArchived = !this.hideArchived;
                this.refreshSmartData();
            },
            columnTotal(column) {
                if (column.jobs.length > 0){
                    return column.jobs.reduce((acc, obj) => acc + obj.taskSubTotal, 0);
                }
                return 0;
            },
            columnColourTotal(colour, column) {
                return column.jobs.filter(job => job.colours.replace('Duralloy ', '') === colour).length;
            },
            columnColours(column){
                if (column.jobs.length > 0){
                    return [...new Set(column.jobs
                      .filter(item => item?.colours) // Filter out null, undefined, and empty strings
                      .map(item => item.colours.replace('Duralloy ', ''))     // Extract the colours property
                    )].sort();
                }
                return '';
            },
            updateCard(column, event) {
                let newIndex = null; 
                let job = null;
                if (event.moved && event.moved.element) {
                    newIndex = event.moved.newIndex;
                    job = event.moved.element;
                }
                if (event.added && event.added.element) {
                    newIndex = event.added.newIndex;
                    job = event.added.element;
                }
                if (newIndex != null && job != null) {
                    this.updateSmartJobStatus({ job: job, status: column.status, taskId: job.jobTaskId, order: newIndex, date: column.statusDate });
                }
            }
        },
        computed: {
            ...mapState({
                user: (state) => state.auth.user,
                loadingData: (state) => state.smart.loadingData,
                smartData: (state) => state.smart.smartData,
                smartKey: (state) => state.smart.smartKey,
            }),
            hideArchived: {
                get() {
                    return this.$store.state.smart.hideArchived
                },
                set(value) {
                    this.$store.commit('setSmartHideArchived', value)
                }
            },
            smartScrollPos: {
                get() {
                    return this.$store.state.smart.smartScrollPos
                },
                set(value) {
                    this.$store.commit('setSmartScrollPos', value)
                }
            },
            smartLock: {
                get() {
                    return this.$store.state.smart.smartLock
                },
                set(value) {
                    this.$store.commit('setSmartLock', value)
                }
            },
            refreshRequired: {
                get() {
                    return this.$store.state.smart.refreshRequired
                },
                set(value) {
                    this.$store.commit('setRefreshRequired', value)
                }
            },
            smartStartDate: {
                get() {
                    return this.$store.state.smart.smartStartDate
                },
                set(value) {
                    this.$store.commit('setSmartStartDate', value)
                }
            },
            smartEndDate: {
                get() {
                    return this.$store.state.smart.smartEndDate
                },
                set(value) {
                    this.$store.commit('setSmartEndDate', value)
                }
            },
            filteredColumns() {
                if (this.searchTerm) {
                    // Filter the columns
                    let upSearchTerm = this.searchTerm.toUpperCase();
                    return this.smartData.map(column => ({
                        ...column, // Keep the column object unchanged
                        jobs: column.jobs.filter(job =>
                            job.name.toUpperCase().includes(upSearchTerm) ||
                            (job.customerNumber && job.customerNumber.toUpperCase().includes(upSearchTerm)) || 
                            (job.colours && job.colours.toUpperCase().includes(upSearchTerm)) || 
                            (job.contact && job.contact.name.toUpperCase().includes(upSearchTerm)))
                    }));
                } else {
                    return this.smartData;
                }
            },
            yesterdayShowing() {
                const today = this.$dayjs();
                return this.$dayjs(this.smartStartDate).date() == today.subtract(1, 'day').date();
            },
        },
        watch: {
            smartKey: {
                handler: function () {
                    if (this.smartScrollPos) {
                        this.$nextTick(() => {
                            this.$refs.smartCont.scrollTop = this.smartScrollPos;
                        });
                    }
                }
            }
        }
    };
</script>