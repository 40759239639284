<template>
    <div>
        <v-overlay :model-value="pretreatmentTableDataLoading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-card variant="flat">
            <v-card-text>
                <div>
                    <v-text-field label="Search..."
                                  prepend-icon="mdi-magnify"
                                  v-model="searchTerm"
                                  density="compact"
                                  clearable></v-text-field>
                </div>
                <v-table v-if="filteredJobsList.length > 0">
                    <thead>
                        <tr>
                            <th class="w-20">#</th>
                            <th class="w-15">Status</th>
                            <th class="w-10">Target</th>
                            <th class="w-20">Customer</th>
                            <th class="w-20">Cust#</th>
                            <th class="w-20"></th>
                        </tr>
                    </thead>
                    <tbody v-for="job in filteredJobsList" :key="job.id">
                        <tr class="job-row" :class="{'rework-job-row':job.jobTag == $constants.jobs.TAG_REWORK, 'overdue-job-row': overdueJob(job)}">
                            <td>
                                <job-link :job="job" :search-term="searchTerm"></job-link>
                            </td>
                            <td>
                                <type-chip :type="job.status" entity="Job" :object="job"></type-chip>
                            </td>
                            <td><span v-if="job.dateTargetCompletion">{{ $dayjs(job.dateTargetCompletion).format("DD/MM/YYYY") }}</span></td>
                            <td><contact-link :contact="job.contact" :search-term="searchTerm"></contact-link></td>
                            <td><span v-html="formatSearchName(job.customerNumberFull, searchTerm)"></span></td>
                            <td class="text-right">
                                <v-btn v-if="!job.pretreated"
                                       prepend-icon="mdi-check-circle"
                                       @click="openSmartPretreated(job)"
                                       color="info">Mark Pretreated</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import { mapActions, mapState } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { formatSearchName } from "../../mixins/searchMixins";
    function initialState() {
        return {
            searchTerm: '',
            loading: false,
        };
    }
    export default {
        name: "PretreatmentTable",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadPretreatmentTableData();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "openSmartPretreated",
                "loadPretreatmentTableData"
            ]),
            numberWithCommas,
            formatSearchName,
            overdueJob(job){
                if (job && job.activeTask && job.activeTask.targetStartDate) {
                    return this.$dayjs(job.activeTask.targetStartDate).isBefore(this.$dayjs().add(1, 'day').endOf('day'));
                }
                return false;
            }
        },
        computed: {
            ...mapState({
                pretreatmentTableData: (state) => state.smart.pretreatmentTableData,
                pretreatmentTableDataLoading: (state) => state.smart.pretreatmentTableDataLoading,
            }),
            filteredJobsList() {
                if (this.pretreatmentTableData) {
                    if (this.searchTerm && this.searchTerm.length > 0) {
                        let upperSearch = this.searchTerm.toUpperCase();
                        return this.pretreatmentTableData.filter(item => {
                            return item.customerNumberFull.toUpperCase().includes(upperSearch) || item.name.toUpperCase().includes(upperSearch) || item.contact.name.toUpperCase().includes(upperSearch);
                        });
                    }
                    return this.pretreatmentTableData;
                }
                return [];
            },
        },
        watch: {
        }
    };
</script>
<style scoped>
    .rework-job-row td {
        background-color: #FFCDD2;
    }
    .job-row td, th{
        border-top: 1px solid #dee2e6;
    }
    .overdue-job-row td {
        background-color: #f0de9e;
    }
</style>