<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-text-field label="Transaction Date"
                      type="date"
                      v-model="editTransaction.transactionDate"></v-text-field>
        <v-select label="Type"
                  v-model="editTransaction.type"
                  :items="$constants.warehouse.types"></v-select>
        <b><v-icon icon="mdi-camera-image" class="mr-1"></v-icon> Photos</b>
        <p>Please take multiple, clear photos of what is coming in or out.</p>
        <div v-bind="getRootProps()" class="vue-dropzone" :class="{'is-dropping':isDragActive}">
            <input v-bind="getInputProps()" />
            <p class="dz-message">Drop files here ...</p>
        </div>
        <v-alert density="compact"
                 v-if="errorFiles.length > 0"
                 class="my-2"
                 :text="'The following files were not loaded as they are not images - '+errorFiles.join(', ')"
                 title="Not all files loaded"
                 type="info"></v-alert>
        <v-table v-if="files && files.length > 0" density="compact" :key="loaderIndex">
            <thead>
                <tr>
                    <th class="w-50"></th>
                    <th class="w-25 text-left">File</th>
                    <th class="w-25 text-left"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(file, index) in files" :key="index">
                    <td>
                        <img :src="file.thumbnail" class="img-thumbnail" />
                    </td>
                    <td>{{ file.name }}</td>
                    <td>
                        <v-btn prepend-icon="mdi-trash-can-outline"
                               size="x-small"
                               @click="files.splice(index, 1)">DELETE</v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <div v-if="editTransaction.type == $constants.warehouse.TYP_INWARD">
            <hr class="my-2" />
            <h4>Inward Details</h4>
            <div v-if="hasContact">
                Loading for {{ activeContact.name }}.
            </div>
            <div v-if="!hasContact">
                <p>Search for the customer who's job is being received.</p>
                <v-text-field :label="'Search for the '+$constants.entities.Contact.SINGULAR+'...'"
                              v-model="contactSearchTerm"
                              :hide-details="true"
                              :loading="contactSearching"></v-text-field>
                <v-list lines="one">
                    <v-list-item v-for="option in contactSearchResults"
                                 :key="option.id"
                                 :value="option"
                                 :active="activeContact && activeContact.id == option.id"
                                 @click="selectContact(option)"
                                 color="primary">
                        <template v-slot:prepend>
                            <v-icon :icon="activeContact && activeContact.id == option.id ? 'mdi-check-circle-outline' : 'mdi-circle-outline'"></v-icon>
                        </template>
                        <template v-slot:title>
                            <span v-html="formatSearchName(option.name, contactSearchTerm)"></span>
                        </template>
                        <template v-slot:subtitle>
                            <div><i>Email: </i><span v-html="formatSearchName(option.accountsEmail, contactSearchTerm)"></span></div>
                            <div><i>Phone: </i><span v-html="formatSearchName(option.phone, contactSearchTerm)"></span></div>
                        </template>
                    </v-list-item>
                </v-list>
                <div v-if="contactSearchResults.length == 0 && contactSearchTerm.length > 2 && !contactSearching">
                    <i>No {{ $constants.entities.Contact.PLURAL }} found. If you think this is a new customer please type the full {{ $constants.entities.Contact.SINGULAR }} below.</i>
                    <v-text-field label="New Customer Name"
                                  :hide-details="true"
                                  v-model="newCustomerName"></v-text-field>
                </div>
            </div>
            <div v-if="activeContact && activeContact.id">
                <hr class="my-2" />
                <v-checkbox v-model="damagedInwards" label="Product Damaged?" :hide-details="true"></v-checkbox>
                <small>If product is damaged please ensure you upload photos of the damage.</small>
                <v-skeleton-loader v-if="loadingJobs"
                                   type="list-item-two-line"></v-skeleton-loader>
                <div v-if="!hasJob">
                    <div v-if="!loadingJobs && contactJobs && contactJobs.length > 0">
                        <p>Below is {{ activeContact.name }}'s unreceived jobs. If the job you are processing is below please select it or select <i>Add New Job.</i></p>
                        <v-table density="compact" class="my-1">
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Date Entered
                                    </th>
                                    <th class="text-left">
                                        Customer#
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="job in contactJobs" :key="job.id" @click="selectContactJob(job)" class="cursor-pointer" :class="{'tr-active': (contactJob && contactJob.id == job.id) }">
                                    <td>{{ job.dateCreated }}</td>
                                    <td>{{ job.customerNumber }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                        <v-btn class="my-2" block color="primary" prepend-icon="mdi-plus" @click="addNewJob = !addNewJob" :disabled="contactJob != null">
                            ADD NEW {{ $constants.entities.Job.SINGULAR }}
                        </v-btn>
                    </div>
                    <div v-if="(!loadingJobs && contactJobs.length == 0) || addNewJob">
                        <i>Enter the customer purchase order number below (if you have it).</i>
                        <v-text-field label="Customer Purchase Order Number"
                                      :hide-details="true"
                                      v-model="customerNumber"></v-text-field>
                    </div>
                </div>
                <div v-if="hasJob">
                    <div v-if="contactJob">
                        <hr class="my-2" />
                        <p>Currently Inward Processing <b>{{ contactJob.name }}</b></p>
                        <p>Customer#: <b>{{ contactJob.customerNumber }}</b></p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="editTransaction.type == $constants.warehouse.TYP_OUTWARD">
            <hr class="my-2" />
            <h4>Outward Details</h4>
            <div v-if="!hasJob">
                <p>Enter the invoice number of the job below.</p>
                <v-text-field label="Search for the job with the INV-...."
                              v-model="jobSearchTerm"
                              :hide-details="true"
                              :loading="jobSearching"></v-text-field>
                <v-table density="compact" class="my-1">
                    <thead>
                        <tr>
                            <th class="text-left">
                                Job#
                            </th>
                            <th class="text-left">
                                For
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="job in jobSearchResults" :key="job.id" @click="selectContactJob(job)" class="cursor-pointer" :class="{'tr-active': (contactJob && contactJob.id == job.id) }">
                            <td><span v-html="formatSearchName(job.name, jobSearchTerm)"></span></td>
                            <td>{{ job.contact.name }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </div>
            <div v-if="contactJob">
                <hr class="my-2" />
                <p>Currently despatching <b>{{ contactJob.name }}</b>, please ensure the customer is picking up all items.</p>
                <p>Customer#: <b>{{ contactJob.customerNumber }}</b></p>
            </div>
        </div>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            se
            <ul>
                <li v-for="error in errors.errors" :key="error" v-html="error[0]"></li>
            </ul>
        </v-alert>
        <v-alert density="compact"
                 type="warning"
                 v-if="saveErrors && saveErrors.length > 0"
                 class="my-2">
            <ul>
                <li v-for="error in saveErrors" :key="error">{{ error }}</li>
            </ul>
        </v-alert>
        <div class="mt-2 pt-2">
            <hr class="my-2" />
            <v-btn :loading="loading"
                   type="submit"
                   :disabled="saveErrors.length != 0"
                   flat
                   color="primary"
                   prepend-icon="mdi-floppy">SAVE</v-btn>
            <v-btn flat class="ml-2" @click="show = false">CANCEL</v-btn>
        </div>
    </v-form>
</template>
<style scoped>
    .tr-active {
        color: rgb(var(--v-theme-primary)) !important;
        background-color: #E0EFFF;
    }
    .vue-dropzone {
        border-radius: 0.25rem;
        background: rgba(0,0,0,.03);
        border: 1px solid rgba(0,0,0,.03);
        padding: 20px 20px;
        min-height: 80px;
    }

    .is-dropping {
        background: #BBDEFB;
        border: #1E88E5;
    }

    .dz-message {
        text-align: center;
        margin: 2em 0;
        cursor: pointer;
    }
    .img-thumbnail {
        width: 80px;
        height: 80px;
        margin: 5px;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { formatSearchName } from "../mixins/searchMixins";
    import { useDropzone } from "vue3-dropzone";
    import { getCurrentInstance, ref } from "vue";
    function initialState() {
        return {
            saving: false,
            loading: false,
            valid: false,
            errors: null,
            files: [],
            editTransaction: {
                id: 0,
                transactionDate: null,
                type: null,
                contact: null
            },
            contactSearchTerm: '',
            contactSearching: false,
            contactSearchResults: [],
            contactSearchTimer: null,
            newCustomerName: '',
            contactJobs: [],
            loadingJobs: false,
            customerNumber: '',
            addNewJob: false,
            contactJob: null,
            jobSearchTerm: '',
            jobSearching: false,
            jobSearchTimer: null,
            jobSearchResults: [],
            damagedInwards: false,
            errorFiles: [],
            loaderIndex: 0,
        };
    }
    export default {
        name: "WarehouseTransactionForm",
        data: function () {
            return initialState();
        },
        component: {
        },
        setup() {
            const { proxy } = getCurrentInstance();
            function onDrop(acceptFiles, rejectReasons) {
                var imageType = /image.*/
                let errorFiles = [];
                Object.keys(acceptFiles).forEach(i => {
                    const file = acceptFiles[i];
                    if (file.type.match(imageType)) {
                        let reader = new FileReader();
                        reader.onload = (e) => {
                            file.thumbnail = e.target.result;
                            proxy.loaderIndex++;
                        };
                        reader.readAsDataURL(file);
                        proxy.files.push(file);
                    } else {
                        errorFiles.push(file.name);
                    }
                });
                proxy.errorFiles = errorFiles;
            }
            const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
            return {
                getRootProps,
                getInputProps,
                ...rest,
            };
        },
        mounted: function () {
            this.editTransaction.transactionDate = this.$dayjs().format('YYYY-MM-DD');
            if (this.hasContact) {
                this.loadContactJobs(this.contact);
            }
            if (this.hasJob){
                this.contactJob = this.job;
            }
            if (this.hasType){
                this.editTransaction.type = this.type;
            }
        },
        props: {
            job: Object,
            contact: Object, 
            type: Number,
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "loadTransactions"
            ]),
            formatSearchName,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                formData.append('type', this.editTransaction.type); 
                formData.append('transactionDate', this.editTransaction.transactionDate); 
                // Load details
                if (this.contactJob) {
                    formData.append('jobId', this.contactJob.id); 
                }
                if (this.activeContact) {
                    formData.append('contactId', this.activeContact.id);
                }
                if (this.customerNumber) {
                    formData.append('customerNumber', this.customerNumber);
                }
                if (this.damagedInwards) {
                    formData.append('damaged', this.damagedInwards);
                }
                if (this.files && this.files.length > 0) {
                    this.files.forEach(file => {
                        formData.append("Files[]", file);
                    });
                }
                this.$api.post('/warehouse', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.show = false;
                        this.loadTransactions();
                        this.reset();
                        this.showSuccessSnack("Add Transaction!");
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else {
                            this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.loading = false;
                    });
            },
            searchContacts() {
                this.contactSearching = true;
                this.$api.get(`${this.$constants.entities.Contact.ROUTE}?searchTerm=${this.contactSearchTerm}&itemsPerPage=5`)
                    .then(res => {
                        this.contactSearching = false;
                        this.contactSearchResults = res.data.items;
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else if (error.response.data.title) {
                            this.errors = { title: "Whoops", errors: [[error.response.data.title]] };
                        } else {
                            this.errors = { title: "Whoops", errors: [["There was an issue saving the colour."]] };
                        }
                        this.contactSearching = false;
                    });
            },
            searchJobs() {
                this.jobSearching = true;
                this.$api.get(`${this.$constants.entities.Job.ROUTE}entityjobs?searchTerm=${this.jobSearchTerm}&itemsPerPage=5`)
                    .then(res => {
                        this.jobSearching = false;
                        this.jobSearchResults = res.data.items;
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else if (error.response.data.title) {
                            this.errors = { title: "Whoops", errors: [[error.response.data.title]] };
                        } else {
                            this.errors = { title: "Whoops", errors: [["There was an issue saving the colour."]] };
                        }
                        this.jobSearching = false;
                    });
            },
            selectContact(option) {
                if (this.editTransaction.contact == option) {
                    this.editTransaction.contact = null;
                } else {
                    this.loadContactJobs(option);
                }
            },
            loadContactJobs(contact) {
                this.editTransaction.contact = contact;
                // Load customer unreceived orders
                this.loadingJobs = true;
                this.$api.get(`${this.$constants.entities.Job.ROUTE}entityjobs?contactId=${this.editTransaction.contact.id}&unreceivedOnly=true`)
                    .then(res => {
                        this.contactJobs = res.data.items;
                        this.loadingJobs = false;
                    }).catch(error => {
                        this.showErrorSnack("There was an issue loading this contacts jobs");
                         this.loadingJobs = false;
                    });
            },
            selectContactJob(job) {
                if (this.contactJob && this.contactJob == job){
                    this.contactJob = null
                } else {
                    this.contactJob = job;
                    this.addNewJob = false;
                }
            },
        },
        computed: {
            ...mapState({
            }),
            show: {
                get() {
                    return this.$store.state.warehouse.showAddTransaction
                },
                set(value) {
                    this.$store.commit('setShowAddTransaction', value)
                }
            },
            hasContact() {
                return typeof this.contact !== 'undefined' && this.contact != null;
            },
            hasJob() {
                return typeof this.job !== 'undefined' && this.job != null;
            },
            hasType() {
                return typeof this.type !== 'undefined' && this.type != null;
            },
            activeContact() {
                if (this.editTransaction.contact != null && this.editTransaction.contact.id){
                    return this.editTransaction.contact;
                }
                return null;
            },
            saveErrors() {
                let result = [];
                if (!this.editTransaction.transactionDate || this.editTransaction.transactionDate.length == 0) {
                    result.push('Need a transaction date');
                }
                if (this.editTransaction.type == null) {
                    result.push('Need a transaction type');
                }
                if (this.files.length == 0) {
                    result.push('Need at least one image');
                }
                if (this.editTransaction.type == this.$constants.warehouse.TYP_INWARD) {
                    // Validate inwards
                    if (this.activeContact){
                        if (this.contactJobs.length > 0 && !this.contactJob && !this.addNewJob){
                            result.push('Select a customers job or select you want to add a new job');
                        }
                    } else {
                        if (!this.newCustomerName || this.newCustomerName.length == 0) {
                            result.push('Need to add the customers name');
                        }
                    }
                } else if (this.editTransaction.type == this.$constants.warehouse.TYP_OUTWARD){
                    // Validate outwards
                    if (!this.contactJob) {
                        result.push('Select the job you are despatching');
                    }
                }
                return result;
            }
        },
        watch: {
            contactSearchTerm: {
                handler: function () {
                    clearTimeout(this.contactSearchTimer);
                    if (this.contactSearchTerm.length > 2) {
                        if (this.contactSearchTerm.length > this.newCustomerName.length){
                            this.newCustomerName = this.contactSearchTerm;
                        }
                        this.contactSearchTimer = setTimeout(this.searchContacts, 100);
                    } else {
                        this.contactSearchResults = [];
                    }
                }
            },
            jobSearchTerm: {
                handler: function () {
                    clearTimeout(this.jobSearchTimer);
                    if (this.jobSearchTerm.length > 2) {
                        this.jobSearchTimer = setTimeout(this.searchJobs, 100);
                    } else {
                        this.jobSearchResults = [];
                    }
                }
            },
        }
    };
</script>