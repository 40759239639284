<template>
    <div v-if="timeline" class="pa-3" id="day-timeline">
        <v-row>
            <v-col cols="3">
                <v-card color="indigo-accent-2" variant="flat" class="text-center mb-2">
                    <template v-slot:subtitle>
                        TOTAL UPTIME
                    </template>
                    <template v-slot:text>
                        <h1>{{ numberWithCommas(timeline.totalLine, 1) }}hrs</h1>
                    </template>
                </v-card>
                <v-card color="amber-accent-2" variant="flat" class="text-center mb-2">
                    <template v-slot:subtitle>
                        COLOUR CHANGES
                    </template>
                    <template v-slot:text>
                        <div><small>COUNT</small></div>
                        <h2>{{ timeline.colourChangeCount }}</h2>
                        <div><small>TIME</small></div>
                        <h2>{{ numberWithCommas(timeline.totalColourChange, 1) }} hrs</h2>
                        <div><small>AVERAGE</small></div>
                        <h2>{{ numberWithCommas(timeline.averageColourChange, 1) }} mins</h2>
                    </template>
                </v-card>
                <v-card color="red-accent-2" variant="flat" class="text-center mb-2">
                    <template v-slot:subtitle>
                        TOTAL BREAK
                    </template>
                    <template v-slot:text>
                        <h1>{{ numberWithCommas(timeline.totalBreak, 1) }}hrs</h1>
                    </template>
                </v-card>
                <v-card color="blue-grey-darken-3" variant="flat" class="text-center mb-2">
                    <template v-slot:text>
                        <div><small>START</small></div>
                        <h2>{{ $dayjs(timeline.startTime).format('hh:mma') }}</h2>
                        <div><small>END</small></div>
                        <h2>{{ $dayjs(timeline.endTime).format('hh:mma') }}</h2>
                        <div><small>TOTAL</small></div>
                        <h2>{{ numberWithCommas(timeline.totalHours, 1) }}hrs</h2>
                    </template>
                </v-card>
            </v-col>
            <v-col cols="9" class="timeline-view">
                <v-timeline align="start" side="end">
                    <v-timeline-item dot-color="blue-grey-darken-3"
                                     size="small">
                        <div class="d-flex">
                            <div>
                                <strong>START {{ $dayjs(timeline.startTime).format('hh:mma') }}</strong>
                            </div>
                        </div>
                    </v-timeline-item>
                    <v-timeline-item v-for="entry in timeline.entries"
                                     :key="entry.description"
                                     :icon="entryIcon(entry)"
                                     :dot-color="entryColour(entry)"
                                     fill-dot
                                     density="compact"
                                     width="100%"
                                     size="small">
                        <div class="d-flex">
                            <v-card class="w-100 timeline-card" density="compact" :color="entryCardColour(entry)">
                                <v-card-text>
                                    <div class="d-flex justify-space-between">
                                        <strong>{{ $dayjs(entry.startTime).format('hh:mma') }} - {{ $dayjs(entry.endTime).format('hh:mma') }}</strong>
                                        <h3>{{ entry.lineTime }}mins</h3>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div>
                                            <div v-if="entry.type == 1">
                                                <span class="me-4">
                                                    <router-link v-if="entry.jobId" :to="'/'+$constants.entities.Job.ROUTE+entry.jobId" @click.stop v-html="entry.description"></router-link>
                                                </span>
                                                <div>
                                                    <div v-if="entry.contact" class="text-caption">
                                                        {{ entry.contact }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="entry.type == 2">
                                                <div>
                                                    <div class="text-caption">
                                                        {{ entry.description }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="entry.type == 3">
                                                <strong class="me-4">
                                                    Break
                                                </strong>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column justify-end">
                                            <h5 v-if="entry.averageDollarHour">${{ numberWithCommas(entry.averageDollarHour,0) }} p/Hr</h5>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-timeline-item>
                    <v-timeline-item dot-color="blue-grey-darken-3"
                                     size="small">
                        <div class="d-flex">
                            <div>
                                <strong>END {{ $dayjs(timeline.endTime).format('hh:mma') }}</strong>
                            </div>
                        </div>
                    </v-timeline-item>
                </v-timeline>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
    .timeline-card .v-card-text {
        padding:10px;
    }
    #day-timeline {
        background: #eceff1 !important;
    }
    .timeline-view {
        height: 90vh;
        overflow-y: auto;
    }
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import { numberWithCommas } from "../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "EntryTimeline",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            timeline: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            numberWithCommas,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            entryColour(entry) {
                switch (entry.type) {
                    case 1: // Job
                        return "blue-darken-1";
                    case 2: // Colourchange
                        return "amber-darken-1";
                    case 3: // Break
                        return "red-darken-1";
                }
            },
            entryCardColour(entry) {
                switch (entry.type) {
                    case 2: // Colourchange
                        return "amber-darken-1";
                    case 3: // Break
                        return "red-darken-1";
                }
            },
            entryIcon(entry) {
                switch (entry.type) {
                    case 1: // Job
                        return "mdi-spray";
                    case 2: // Colourchange
                        return "mdi-pail-outline";
                    case 3: // Break
                        return "mdi-food-fork-drink";
                }
            }
        },
        computed: {
        }
    };
</script>