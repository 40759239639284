<template>
    <div class="buttons">
        <div class="button-controls d-flex justify-space-between">
            <v-btn prepend-icon="mdi-printer" @click="printPage">Print</v-btn>
            <div class="w-25">
                <v-select label="Week"
                          v-model="selectedWeek"
                          item-title="text"
                          item-value="startDate"
                          :items="weeks"></v-select>
            </div>
        </div>
    </div>
    <div class="book">
        <div class="page">
            <div class="subpage">
                <v-overlay :model-value="loading"
                           class="align-center justify-center">
                    <v-progress-circular color="primary"
                                         indeterminate
                                         size="64"></v-progress-circular>
                </v-overlay>
                <div v-if="data">
                    <h4 v-if="selectedWeekDetails"><small style="color: #757575;"><v-icon icon="mdi-calendar-range"></v-icon> FOR THE WEEK</small> {{ selectedWeekDetails.text }}</h4>
                    <v-sheet color="grey-lighten-4"
                             class="pa-2 mb-2"
                             rounded>
                        <div class="d-flex justify-space-between">
                            <h3><small><v-icon icon="mdi-arrow-expand-right" class="mr-1"></v-icon></small>RECEIVED</h3>
                            <div class="text-right w-33">EXTENDED <h4 class="d-inline-block">${{ numberWithCommas(data.receivedExtendedTotal, 0) }}</h4></div>
                            <div>
                                <h3>${{ numberWithCommas(data.receivedTotal, 0) }} <small><budget-percentage :value="data.receivedTotal" :budget="data.receivedBudget"></budget-percentage></small></h3>
                            </div>
                        </div>
                        <v-sheet class="pa-1" rounded>
                            <v-row>
                                <v-col cols="6">
                                    <v-table density="compact" class="top-5-table">
                                        <tbody v-for="(topCustomer, index) in data.receivedTopContacts.slice(0, 3)" :key="index">
                                            <tr v-if="topCustomer" >
                                                <td class="pa-0">{{ index+1 }}) {{ topCustomer.name }}</td>
                                                <td class="text-right pa-0">${{ numberWithCommas(topCustomer.total, 2) }}</td>
                                            </tr>
                                        </tbody>
                                    </v-table>
                                </v-col>
                                <v-col cols="6">
                                    <v-table density="compact" class="top-5-table">
                                        <tbody v-for="(topCustomer, index) in data.receivedTopContacts.slice(3, 6)" :key="index">
                                            <tr v-if="topCustomer" >
                                                <td class="pa-0">{{ index+4 }}) {{ topCustomer.name }}</td>
                                                <td class="text-right pa-0">${{ numberWithCommas(topCustomer.total, 2) }}</td>
                                            </tr>
                                        </tbody>
                                    </v-table>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-sheet>
                    <v-sheet color="grey-lighten-4"
                             class="pa-2 mb-2"
                             rounded>
                        <div class="d-flex justify-space-between">
                            <h3><small><v-icon icon="mdi-invoice-text-check-outline" class="mr-1"></v-icon></small> INVOICED</h3>
                            <div class="text-right w-33" v-if="data.invoicedCreditTotal != 0"> CREDITS <h4 class="d-inline-block">${{ numberWithCommas(data.invoicedCreditTotal * -1, 0) }}</h4></div>
                            <div>
                                <h3>${{ numberWithCommas(data.invoicedTotal, 0) }} <small><budget-percentage :value="data.invoicedTotal" :budget="data.invoicedBudget"></budget-percentage></small></h3>
                            </div>
                        </div>
                    </v-sheet>
                    <v-sheet color="grey-lighten-4"
                             class="pa-2 mb-2"
                             rounded>
                        <div class="d-flex justify-space-between">
                            <h3><small><v-icon icon="mdi-warehouse" class="mr-1"></v-icon></small> PRODUCTION</h3>
                            <div class="text-right w-33" v-if="data.blastStripProductionTotal != 0"> NON-LINE <h4 class="d-inline-block">${{ numberWithCommas(data.otherProductionTotal, 0) }}</h4></div>
                            <div>
                                <h3>${{ numberWithCommas(data.productionTotal, 0) }} <small><budget-percentage :value="data.productionTotal" :budget="data.productionBudget"></budget-percentage></small></h3>
                            </div>
                        </div>
                        <v-sheet v-for="(line, index) in data.productionLines" :key="index" rounded class="pa-2 mb-1">
                            <div v-if="line">
                                <v-row>
                                    <v-col cols="4">
                                        <div>
                                            <h4>Line {{ line.line }}</h4>
                                            <h2>${{ numberWithCommas(line.total, 0) }} <small><budget-percentage :value="line.total" :budget="line.lineBudget"></budget-percentage></small></h2>
                                        </div>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-table density="compact" class="top-5-table average-table">
                                            <tbody>
                                                <tr>
                                                    <td class="w-30 week-cell">UPTIME</td>
                                                    <td class="text-right w-20 week-cell">{{ numberWithCommas(line.uptimeAverage, 1) }} <small>hrs</small></td>
                                                    <td class="w-30 week-cell">COLOUR.C.</td>
                                                    <td class="text-right w-20 week-cell">{{ numberWithCommas(line.colourChangeAverage, 0) }} ({{ numberWithCommas(line.colourChangeTimeAverage, 1) }} <small>mins</small>)</td>
                                                </tr>
                                                <tr>
                                                    <td class="w-30 week-cell">BREAK</td>
                                                    <td class="text-right w-20 week-cell">{{ numberWithCommas(line.breakAverage, 0) }} <small>mins</small></td>
                                                    <td class="w-30 week-cell">$ PER/HR</td>
                                                    <td class="text-right w-20 week-cell">${{ numberWithCommas(line.perHourAverage, 0) }} <small>p/hr</small></td>
                                                </tr>
                                            </tbody>
                                        </v-table>
                                    </v-col>
                                </v-row>
                                <v-table density="compact" class="top-5-table mt-1">
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <td v-for="day in days" :key="day" class="week-cell">{{ day }}</td>
                                        </tr>
                                        <tr>
                                            <td><b>TOTAL</b></td>
                                            <td v-for="(day, index) in days" :key="day" class="week-cell"><b v-if="line.days[index]">${{ numberWithCommas(line.days[index].total, 0) }}</b></td>
                                        </tr>
                                        <tr>
                                            <td>UPTIME</td>
                                            <td v-for="(day, index) in days" :key="day" class="week-cell"><span v-if="line.days[index]">{{ numberWithCommas(line.days[index].uptime, 1) }}</span></td>
                                        </tr>
                                        <tr>
                                            <td>COLOUR.C.</td>
                                            <td v-for="(day, index) in days" :key="day" class="week-cell"><span v-if="line.days[index]">{{ numberWithCommas(line.days[index].colourChange, 0) }}</span></td>
                                        </tr>
                                        <tr>
                                            <td>AVG C.C</td>
                                            <td v-for="(day, index) in days" :key="day" class="week-cell"><span v-if="line.days[index]">{{ numberWithCommas(line.days[index].colourChangeTime, 1) }}</span></td>
                                        </tr>
                                        <tr>
                                            <td>REWORK</td>
                                            <td v-for="(day, index) in days" :key="day" class="week-cell"><span v-if="line.days[index]">{{ numberWithCommas(line.days[index].rework, 1) }}</span></td>
                                        </tr>
                                        <tr>
                                            <td>BREAK</td>
                                            <td v-for="(day, index) in days" :key="day" class="week-cell"><span v-if="line.days[index]">{{ numberWithCommas(line.days[index].break, 1) }}</span></td>
                                        </tr>
                                        <tr>
                                            <td>$ PER/HR</td>
                                            <td v-for="(day, index) in days" :key="day" class="week-cell"><span v-if="line.days[index]">{{ numberWithCommas(line.days[index].perHour, 1) }}</span></td>
                                        </tr>
                                        <tr>
                                            <td>START</td>
                                            <td v-for="(day, index) in days" :key="day" class="week-cell"><span v-if="line.days[index]">{{ line.days[index].start }}</span></td>
                                        </tr>
                                        <tr>
                                            <td>END</td>
                                            <td v-for="(day, index) in days" :key="day" class="week-cell"><span v-if="line.days[index]">{{ line.days[index].end }}</span></td>
                                        </tr>
                                    </tbody>
                                </v-table>
                                <div v-if="line.topContacts && line.topContacts.length > 0" class="day-block">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-table density="compact" class="top-5-table">
                                                <tbody v-for="(topCustomer, index) in line.topContacts.slice(0,3)" :key="index">
                                                    <tr v-if="topCustomer">
                                                        <td class="pa-0">{{ index+1 }}) {{ truncate(topCustomer.name, 15) }}</td>
                                                        <td class="text-right pa-0">${{ numberWithCommas(topCustomer.total, 2) }}</td>
                                                        <td class="text-right pa-0">${{ numberWithCommas(topCustomer.perHour, 2) }} <small>p/hr</small></td>
                                                    </tr>
                                                </tbody>
                                            </v-table>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-table density="compact" class="top-5-table">
                                                <tbody v-for="(bottomCustomer, index) in line.bottomContacts.slice(0,3)" :key="index">
                                                    <tr v-if="bottomCustomer">
                                                        <td class="pa-0">{{ index+1 }}) {{ truncate(bottomCustomer.name, 15) }}</td>
                                                        <td class="text-right pa-0">${{ numberWithCommas(bottomCustomer.total, 2) }}</td>
                                                        <td class="text-right pa-0">${{ numberWithCommas(bottomCustomer.perHour, 2) }} <small>p/hr</small></td>
                                                    </tr>
                                                </tbody>
                                            </v-table>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-sheet>
                        <v-table density="compact" class="top-5-table">
                            <tbody>
                                <tr>
                                    <td v-for="(day, index) in days" :key="day" class="comment-box">
                                        <h5>{{ day }}</h5>
                                        <div v-if="data.comments[index]">
                                            <div v-for="(comment, commentIndex) in data.comments[index]" :key="commentIndex" class="comment">
                                                <b>{{ comment.by }} - </b> {{ comment.body }}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-sheet>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
    @import '../styles/portraitprint.scss';
    .book {
        margin-top:140px;
    }
    .v-table--density-compact > .v-table__wrapper > table > tbody > tr > .comment-box {
        height: 100px !important;
        border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        vertical-align: top;
        padding:2px;
        width:16.6%;
    }
    .average-table {
        background: #E1F5FE;
        border-radius: 5px;
        font-weight: bold;
        border: 1px solid #01579B;
        padding: 4px;
    }
    .comment-box {
        font-size: 8px;
        border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    }
    @media print {
        .book {
            margin-top: 0px;
            margin-bottom:0px;
            background-color:white;
        }
        #app html body .v-application__wrap {
            background-color: white;
        }
        .page {
            min-height:auto;
            padding-top:5px;
        }
    }
    .number-cell {
        text-align: right !important;
    }

    .footnote-text {
        font-size: 12px;
    }
    .week-table {
        font-size:13px;
    }
    .week-cell {
        width: 14.28%;
    }
    .top-5-table {
        font-size:11px;
    }
    .v-table--density-compact > .v-table__wrapper > table > tbody > tr > td, .v-table--density-compact > .v-table__wrapper > table > tbody > tr > th {
        height: 17px !important;
    }
    .day-block {
        border-top: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        margin-top:5px;
        padding-top:5px;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { getTagClass } from '../mixins/jobMixins';
    import { truncate } from '../mixins/stringMixins';
    import jobs from "../constants/jobs";
    var weekday = require("dayjs/plugin/weekday");
    var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
    import { numberWithCommas } from "../mixins/numberMixins";
    function initialState() {
        return {
            loading: false,
            weeks: [],
            errors: '',
            data: null,
            selectedWeek: null,
            days: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ],
        };
    }
    export default {
        name: "WeeklyProductionSummary",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.$dayjs.extend(weekday);
            this.$dayjs.extend(isSameOrBefore);
            let currentDate = this.$dayjs().weekday(0); // Start from the last Sunday
            for (let i = 0; i < 12; i++) {
                const startDate = currentDate.startOf('week').add(1, 'day'); // Monday
                const endDate = currentDate.endOf('week').add(1, 'day'); // Sunday

                this.weeks.unshift({
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                    text: `${startDate.format('D MMMM')} - ${endDate.format('D MMMM')}`
                });

                currentDate = currentDate.subtract(1, 'week'); // Move to the previous week
            }
            this.weeks = this.weeks.reverse();
            this.selectedWeek = this.weeks[0].startDate;
            // Load weeks
            this.loadReport();
        },
        props: {
        },
        methods: {
            ...mapActions([
                'showErrorSnack'
            ]),
            truncate,
            numberWithCommas,
            getTagClass,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            printPage() {
                var css = '@page { size: A4 portrait!important; }',
                    head = document.head || document.getElementsByTagName('head')[0],
                    style = document.createElement('style');

                style.type = 'text/css';
                style.media = 'print';

                if (style.styleSheet){
                  style.styleSheet.cssText = css;
                } else {
                  style.appendChild(document.createTextNode(css));
                }

                head.appendChild(style);
                window.print();
            },
            loadReport(){
                this.loading = true;
                let query = [];
                if (this.selectedWeekDetails) {
                    query.push('startDate='+this.selectedWeekDetails.startDate);
                    query.push('endDate='+this.selectedWeekDetails.endDate);
                }
                this.$api.get('report/weeklyprodsumm?' + query.join('&'))
                    .then(res => {
                        this.loading = false;
                        this.data = res.data;
                    }).catch(error => {
                        this.loading = false;
                        this.showErrorSnack("There was an issue getting the report data");
                    });
            },
        },
        computed: {
            selectedWeekDetails() {
                if (this.selectedWeek) {
                    return this.weeks.find(week => week.startDate === this.selectedWeek);
                }
                return null;
            }
        },
        watch: {
            selectedWeek: {
                handler() {
                    this.loadReport();
                }
            }
        }
    };
</script>