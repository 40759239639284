<template>
    <v-badge v-if="percentage != 0" :color="percentage >= 100 ? 'green-darken-1' : '#D32F2F'"
             :content="numberWithCommas(percentage, 0)+'%'"
             inline></v-badge>
</template>
<style scoped>
    .percent-up {
        color: #7CB342;
        font-size: 20px;
    }

    .percent-down {
        color: #FB8C00;
        font-size: 20px;
    }
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "BudgetPercentage",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            value: Number,
            budget: Number
        },
        methods: {
            numberWithCommas
        },
        computed: {
            percentage() {
                if (this.budget != 0) {
                    return (this.value / this.budget) * 100;
                }
                return null;
            }
        }
    };
</script>